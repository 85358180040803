<template>
  <div id="app">
    <div class="app-wrapper">
      <div class="sidebar-wrapper" id="sidebar-wrapper">
        <div key="1" id="sidebar" v-if="!sidebarToggled">
          <div class="logo">
            <router-link class="text-center" to="/home">
              {{ appName }}
              <span>{{ env }}</span>
            </router-link>
          </div>
          <div class="pt10">
            <side-bar :list="menus"></side-bar>
          </div>
        </div>
      </div>
      <div class="main-wrapper" id="main-wrapper">
        <nav-bar></nav-bar>
        <div class="wrapper">
          <div class="app-main">
            <tool-bar
              :componentId="this.$route.meta.componentId"
              :table="this.$route.meta.table"
            />
            <div :key="isGripOptionsChanges"> <slot /> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    name: "Default-Layout",
    data() {
      return {
        appName: "SPOCKLOUD",
        env: "PRD",
        showModal: false,
        viewFunction: Function,
        addFunction: Function,
        copyFunction: Function,
        saveFunction: Function,
        updateFunction: Function,
        editFunction: Function,
        deleteFunction: Function,
        clearFormFunction: Function,
        importFunction: Function,
        exportFunction: Function,
        downloadFunction: Function,
        showLogsFunction: Function,
        specialFunction: Function,
        filterFunction: Function,
        changeEtaFunction: Function,
        addDocumentFunction: Function,
        viewDocumentFunction: Function,
        openModalFunction: Function,
        downloadImportTemplateFunction: Function,
        menulist: null,
        sidebarToggled: false,
        isGripOptionsChanges: 0,
        isLoading: false
      };
    },
    computed: {
      menus() {
        if (!this.$utils.isNullOrUndefined(this.$store.state.login.user)) {
          return this.$store.state.login.user.menus;
        } else {
          return this.menulist;
        }
      }
    }
  };
</script>
